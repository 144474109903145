/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby";
import appLogo from "../../images/logo.png";

const Logo = ({siteTitle}) => {
    return (
        <h1 sx={{
            padding: "17px 10px",
            marginRight: 35,
            "@media (max-width: 1100px)": {
                marginRight: "5px",
            },
        }}>
            <Link to="/" sx={{
                display: "inline-block",
                "img": {
                    transition: "all 200ms ease-in",
                    width: 223,
                    "@media (max-width: 450px)": {
                        marginRight: "5px",
                        width: 180,
                    },
                }
            }}>
                <img src={appLogo} alt={siteTitle} style={{verticalAlign: "bottom"}} />
            </Link>
        </h1>
    )
}

Logo.Logo = {
    siteTitle: PropTypes.string.isRequired,
    isSticky: PropTypes.bool,
}

export default Logo

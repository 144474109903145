/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import NavbarLinks from "./navbar-links"
import NavbarHamburger from "./navbar-hamburger";
import Logo from "./logo";
import { throttle } from 'lodash';
import contactIcon from "../../images/838_ma_h.svg";
import {Link} from "gatsby";

const Header = ({siteTitle, isTop}) => {
    const [navbarOpen, setNavbarOpen] = useState(false)

    const [isSticky, setSticky] = useState(false);

    const handleScroll = () => {
        const { scrollTop: currentScrollTop } = document.documentElement || document.body;
        setSticky(currentScrollTop >= 20);
    };

    const handleScrollThrottled = throttle(handleScroll, 50);

    useEffect(() => {
        window.addEventListener('scroll', handleScrollThrottled);

        return () => {
            window.removeEventListener('scroll', () => handleScrollThrottled);
        };
    }, []);

    const toggleNavbarOpen = () => {
        setNavbarOpen(!navbarOpen)
    };

    return (
        <div sx={{
            position: "fixed",
            width: "100%",
            top: 0,
            left: 0,
            zIndex: 2,
            backgroundColor: "primary",
        }}>
            <div sx={{
                position: "relative",
                display: "flex",
                height: "100%",
                alignItems: "center",
                margin: "0 auto",
                padding: 0,
                zIndex: 2,
                alignSelf: "center",
                maxWidth: "1600px"
            }}>
                <Logo siteTitle={siteTitle} isSticky={isSticky} />

                {navbarOpen === false && <Link sx={{
                    background: "#E70A84",
                    paddingLeft: 25,
                    paddingRight: 25,
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    fontWeight: "bold",
                    textDecoration: "none",
                    color: "#fff",
                    whiteSpace: "nowrap",
                    transition: "all 200ms ease-in",
                    fontSize: 16,
                    "> img": {
                        marginRight: "5px",
                        minWidth: 24
                    },
                    ":after": {
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        width: "0%",
                        content: '"."',
                        color: "transparent",
                        background: "#E7398F",
                        height: "3px",
                        transition: "all 0.4s ease-in",
                    },
                    ":hover": {
                        "&::after": {
                            width: "100%",
                        },
                    },
                    "@media (min-width: 1000px)": {
                        display: "none"
                    },
                    "@media (max-width: 1000px)": {
                        width: "74px",
                        color: "#000",
                        fontSize: "1.5rem",
                        zIndex: 6,
                        right: 74,
                        "> span": {
                            display: "none"
                        },
                        "> img": {
                            minWidth: 40,
                            marginRight: "0px",
                        },
                    }
                }} to={`/#contact`}><img src={contactIcon} /><span>よくある質問</span>
                </Link>}

                <NavbarHamburger open={navbarOpen} onClick={toggleNavbarOpen} />

                <NavbarLinks open={navbarOpen} isTop={isTop || false} onClick={toggleNavbarOpen} />
            </div>
        </div>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
    isTop: PropTypes.bool,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header

/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types";
import scrollTo from 'gatsby-plugin-smoothscroll';

const NavbarLink = ({children, to, scroll, onClick}) => {
    return (
        <>
            {!to && scroll?
                <Link sx={{
                    fontWeight: "bold",
                    textDecoration: "none",
                    color: "#fff",
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    transition: "all 200ms ease-in",
                    position: "relative",
                    fontSize: 14,
                    ":after": {
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        width: "0%",
                        content: '"."',
                        color: "transparent",
                        background: "#E7398F",
                        height: "3px",
                        transition: "all 0.4s ease-in",
                    },
                    ":hover": {
                        "&::after": {
                            width: "100%",
                        },
                    },
                    "@media (max-width: 1000px)": {
                        color: "#fff",
                        padding: "30px 0",
                        fontSize: "1.5rem",
                        zIndex: 6,
                        textAlign: "center"
                    }
                }} to={`/${scroll}`} onClick={(e) => {
                    onClick();
                }}>{children}</Link>: <a sx={{
                    textDecoration: "none",
                    color: "#fff",
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    transition: "all 200ms ease-in",
                    position: "relative",
                    fontSize: 16,
                    ":after": {
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        width: "0%",
                        content: '"."',
                        color: "transparent",
                        background: "#E7398F",
                        height: "3px",
                        transition: "all 0.4s ease-in",
                    },
                    ":hover": {
                        "&::after": {
                            width: "100%",
                        },
                    },
                    "@media (max-width: 1000px)": {
                        color: "#fff",
                        padding: "20px 0",
                        fontSize: "1.5rem",
                        zIndex: 6,
                        textAlign: "center"
                    }
                }} href={to}>
                    {children}
                </a>}
        </>
    )
}

NavbarLink.propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.string,
    scroll: PropTypes.string,
    onClick: PropTypes.func.isRequired,
}

export default NavbarLink
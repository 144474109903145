/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import appLogo from "../../images/logo.png";

const Footer = () => {
    return (
        <div style={{
            background: "#fff"
        }}>
            <div sx={{
                position: "relative",
                margin: "0 auto",
                zIndex: 1,
                alignSelf: "center",
                padding: `40px 15px`,
                background: "#313131",
                textAlign: "center"
            }}>
                <div>
                    <img src={appLogo} alt={""} style={{verticalAlign: "bottom"}} />
                </div>

                <p style={{
                    textAlign: "center",
                    fontSize: 16,
                    lineHeight: 2,
                    marginTop: 10,
                    marginBottom: 20,
                    paddingTop: 21,
                    color: "#fff",
                }}>
                    株式会社クロスマーケット<br />
                    03-6820-2836（代表）<br />
                    東京都渋谷区恵比寿2-28-10
                </p>

                <p style={{
                    textAlign: "center",
                    fontSize: 13,
                    marginTop: 10,
                    paddingTop: 21,
                    color: "#fff",
                }}>
                     copyright © SNS MANGALABO all right reserved.
                </p>
            </div>
        </div>
    )
}

export default Footer

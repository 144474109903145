/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import NavbarLink from "./navbar-link";
import PropTypes from "prop-types";
import {Link} from "gatsby";
import contactIcon from "../../images/838_ma_h.svg"

const NavbarLinks = ({open, isTop, onClick}) => {
    return (
        <div sx={{
            top: 0,
            right: 0,
            display: "flex",
            height: "100%",
            justifyContent: "flex-end",
            "> span": {
                color: "#fff",
                margin: "0 5px"
            },
            "> a": {
                marginRight: 15,
            },
            "> a:last-child": {
                marginRight: 0,
            },
            "@media (max-width: 1100px)": {
                "> a": {
                    marginRight: 10,
                }
            },
            "@media (max-width: 1000px)": {
                paddingTop: 105,
                flexDirection: "column",
                position: "fixed",
                width: "100%",
                justifyContent: "flex-start",
                backgroundColor: "#313131",
                transition: "all 0.3s ease-in",
                top: 0,
                left: open? "0" : "-100%",
                "> span": {
                    display: "none"
                },
            }
        }}>
            <NavbarLink to={!isTop? "/#top": ""} scroll={"#top"}  onClick={onClick}>トップ</NavbarLink>
            <NavbarLink to={!isTop? "/#issues": ""} scroll={"#issues"}  onClick={onClick}>お悩み</NavbarLink>
            <NavbarLink to={!isTop? "/#benefits": ""} scroll={"#benefits"}  onClick={onClick}>４つのメリット</NavbarLink>
            <NavbarLink to={!isTop? "/#plan": ""} scroll={"#plan"}  onClick={onClick}>プラン・特徴</NavbarLink>
            <NavbarLink to={!isTop? "/#works": ""} scroll={"#works"}  onClick={onClick}>制作実績</NavbarLink>
            <NavbarLink to={!isTop? "/#flow": ""} scroll={"#flow"}  onClick={onClick}>発注・制作の流れ</NavbarLink>
            <NavbarLink to={!isTop? "/#faq": ""} scroll={"#faq"}  onClick={onClick}>よくある質問</NavbarLink>
            <Link sx={{
                background: "#E70A84",
                paddingLeft: 25,
                paddingRight: 25,
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                right: 0,
                top: 0,
                fontWeight: "bold",
                textDecoration: "none",
                color: "#fff",
                whiteSpace: "nowrap",
                transition: "all 200ms ease-in",
                fontSize: 16,
                "> img": {
                    marginRight: "5px",
                    minWidth: 24
                },
                ":after": {
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: "0%",
                    content: '"."',
                    color: "transparent",
                    background: "#E7398F",
                    height: "3px",
                    transition: "all 0.4s ease-in",
                },
                ":hover": {
                    "&::after": {
                        width: "100%",
                    },
                },
                "@media (max-width: 1000px)": {
                    width: "74px",
                    height: "74px",
                    color: "#000",
                    fontSize: "1.5rem",
                    zIndex: 6,
                    right: 74,
                    "> span": {
                        display: "none"
                    },
                    "> img": {
                        marginRight: "0px",
                        minWidth: 40
                    },
                }
            }} to={`/#contact`} onClick={(e) => {
                onClick();
            }}><img src={contactIcon} /><span>よくある質問</span></Link>
        </div>
    )
}

NavbarLinks.propTypes = {
    open: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    isTop: PropTypes.bool,
}

export default NavbarLinks
/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types";

const NavbarHamburger = ({open, onClick}) => {
    return (
        <div
            sx={{
                position: "absolute",
                top: "50%",
                marginTop: "-15px",
                right: 24,
                display: "none",
                height: "29px",
                cursor: "pointer",
                zIndex: 1,
                "@media (max-width: 1000px)": {
                    display: "flex",
                }
            }}
            onClick={onClick}
        >
            <div sx={{
                backgroundColor: open? "#fff" : "#fff",
                width: "27px",
                height: "2px",
                transition: "all .3s linear",
                alignSelf: "center",
                position: "relative",
                transform: open ? "rotate(-45deg)" : "inherit",
                zIndex: 2,
                "::before, ::after": {
                    backgroundColor: open? "#fff" : "#fff",
                    width: "27px",
                    height: "2px",
                    content: '""',
                    position: "absolute",
                    transition: "all 0.3s linear",
                },
                "::before": {
                    transform: open? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)",
                    top: "-10px",
                },
                "::after": {
                    opacity: open? "0" : "1",
                    transform: open? "rotate(90deg) " : "rotate(0deg)",
                    top: "10px",
                }
            }} />
        </div>
    )
}

NavbarHamburger.propTypes = {
    open: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
}

export default NavbarHamburger